<template>
  <div class="new-address">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }"><i class="el-icon-s-home"></i></el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/user' }">会员中心</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/addressMana' }">地址管理</el-breadcrumb-item>
      <el-breadcrumb-item>新增地址</el-breadcrumb-item>
    </el-breadcrumb>
    <div>
      <h3>新增地址</h3>
      <el-form label-width="120px">
        <el-form-item label="名字">
          <el-input v-model="form.name"  placeholder="名字"></el-input>
        </el-form-item>
        <el-form-item label="联系电话">
          <el-input v-model="form.phone" placeholder="联系电话"></el-input>
        </el-form-item>
        <el-form-item label="公司名称">
          <el-input v-model="form.companyName" placeholder="公司名称"></el-input>
        </el-form-item>
        <el-form-item label="区域">
          <el-cascader
            @change="areaChange"
            placeholder="选择地区"
            :options="options"
            filterable
             v-model="region"
          />
        </el-form-item>
        <el-form-item label="详细地址">
          <el-input v-model="form.address" placeholder="详细地址"></el-input>
        </el-form-item>
        <el-form-item label="邮编地址">
          <el-input v-model="form.postcodeAddr" placeholder="邮编地址"></el-input>
        </el-form-item>
        <el-form-item label="默认地址">
          <el-radio v-model="defaultAddress" label="0">是</el-radio>
          <el-radio v-model="defaultAddress" label="5">否</el-radio>
        </el-form-item>
        <div style="display: flex;justify-content: space-between">
          <el-button @click="$router.push('/addressMana')">返回</el-button>
          <el-button @click="submit">提交</el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
// CodeToText
//provinceAndCityData是省市二级联动数据（不带“全部”选项）
//regionData是省市区三级联动数据（不带“全部”选项）
//provinceAndCityDataPlus是省市区三级联动数据（带“全部”选项）
//regionDataPlus是省市区三级联动数据（带“全部”选项）
//"全部"选项绑定的value是空字符串""
//CodeToText是个大对象，属性是区域码，属性值是汉字 用法例如：CodeToText['110000']输出北京市
//TextToCode是个大对象，属性是汉字，属性值是区域码 用法例如：TextToCode['北京市'].code输出110000,TextToCode['北京市']['市辖区'].code输出110100,TextToCode['北京市']['市辖区']['朝阳区'].code输出110105
import { regionData,CodeToText,TextToCode  } from 'element-china-area-data'
import { http } from '../../../request/http'
export default {
  name: 'newAddress',
  data () {
    return {
      options: regionData,
      region: [],
      defaultAddress: '5',
      form:{
        name:'', //姓名
        phone: '', //电话
        companyName:'', //公司名称
        address: '', // 详细地址
        postcodeAddr:'' // 邮编地址
      }
    }
  },
  mounted() {
    console.log(this.options);
  },
  methods: {
    // handleChange (value) {
    //   if (value[1] != null && value[2] != null) {
    //     list.receiver.provinceName = CodeToText[value[0]];
    //     list.receiver.cityName = CodeToText[value[1]];
    //     list.receiver.areaName = CodeToText[value[2]];
    //     var dz = CodeToText[value[0]] + '/' + CodeToText[value[1]] + '/' + CodeToText[value[2]];
    //     ruleForm.postCod = value[2]
    //     console.log()
    //   } else {
    //     if (value[1] != null) {
    //       list.receiver.provinceName = CodeToText[value[0]];
    //       list.receiver.cityName = CodeToText[value[1]];
    //       dz = CodeToText[value[0]] + '/' + CodeToText[value[1]]
    //       ruleForm.postCod = value[1]
    //     } else {
    //       list.receiver.provinceName = CodeToText[value[0]];
    //       dz = CodeToText[value[0]]
    //       ruleForm.postCod = value[0]
    //     }
    //   }
    //   console.log(dz)
    //   console.log(value)
    // }
    areaChange(e) {
      console.log(e);
      // this.region = []
    },
    // 提交
    submit() {
      let province = CodeToText[this.region[0]]
      let city = CodeToText[this.region[1]]
      let region = CodeToText[this.region[2]]
      let { defaultAddress } = this;
      let form = this.form
       http('user.php', {
       fun: 'addUserAddress',
       contract_name: form.name, //姓名
       contract_phone: form.phone, //电话
       province, //省份
       city, //城市
       county: region, //区
       address: form.address, //详细地址
       type: defaultAddress //默认地址
      }).then(res => {
        if (res.code == 0) {
           this.$message({
            message: '添加成功',
            type: 'success'
          });
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/scss/index";
.new-address {
  @include body;
}
</style>
